<template>
    <div style="margin: -15px -15px; background-color: #f0f2f5">
      <div style="padding: 20px; background-color: #fff">
        <a-table
          :columns="columns"
          style="min-height: 500px; background-color: #fff"
          :data-source="HeplTable"
          :rowKey="(record) => record.id"
          :pagination="false"
        >
          <span slot="source" slot-scope="text, item, index,record">
            <span v-if="item.contentType == 'EXAM'">职称考试</span>
            <span v-if="item.contentType == 'DOCUMENT'">文献指南</span>
            <span v-if="item.contentType == 'EDUCATION'">进修招聘</span>
            <span v-if="item.contentType == 'ARTICLE'">文章</span>
            <span v-if="item.contentType == 'VOD'">点播</span>
            <span v-if="item.subContentType == 'MIX_SET_TOPIC'">--专题</span>
            <span v-if="item.subContentType == 'VOD_SINGLE'">--视频</span>
            <span v-if="item.subContentType == 'EXAM_INFO'">--考试信息</span>
            <span v-if="item.subContentType == 'EXAM_CHOISE'">--考点精选</span>
            <span v-if="item.contentType  == 'MAGAZINE'">医学杂志</span>
            <!-- <span v-if="item.subContentType == 'ARTICLE_SINGLE'">--文章</span> -->
          </span>
          <span slot="sort" slot-scope="text, item, index">
            <a v-if="index === 0 && pagination.pageNum == 1" @click="sortList('down',item)" style="margin-left:5px">下移</a>
            <a v-else-if="index === HeplTable.length -1 && pagination.pageNum == Math.ceil(pagination.total / pagination.pageSize)" @click="sortList('up',item)">上移</a>
            <span v-else>
              <a @click="sortList('up',item)">上移</a>
              <a @click="sortList('down',item)" style="margin-left:5px">下移</a>
            </span>
          </span>
        </a-table>
        <!-- table框的分页 -->
        <div
          style="
            margin-top: 28px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <span
            style="
              font-size: medium;
              color: #929292;
              margin-left: 3%;
              font-size: 14px;
              bottom: 3%;
            "
          >
            共{{ pagination.total }}条记录 第 {{ pagination.pageNum }} /
            {{ Math.ceil(pagination.total / pagination.pageSize) }} 页
          </span>
          <a-pagination
            style="float: right"
            show-quick-jumper
            show-size-changer
            :page-size.sync="pagination.pageSize"
            :total="pagination.total"
            v-model="pagination.current"
            @change="pageChange"
            @showSizeChange="pageChange"
          />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  //   import {getSearchRecord,} from "@/service/DrugQueryManagement";
  import {
    getminaRecommendList,putminaRecommendList
  } from "../../../service/document_api";
  export default {
    data() {
      return {
        timeVal: [],
        pagination: {
          //存储大table的分页
          total: 0,
          current: 1,
          pageSize: 10,
          pageNum: 1,
        },
        columns: [
          {
            title: "标题",
            dataIndex: "title",
            key: "title",
          },
          {
            title: "来源",
            scopedSlots: { customRender: "source" },
          },
          {
            title: "创建时间",
            dataIndex: "createdTime",
            key: "createdTime",
          },
          {
            title: "排序",
            scopedSlots: { customRender: "sort" },
          },
        ],
        HeplTable: [],
        StartTime: "",
        EndTime: "",
      };
    },
  
    created() {
      this.getTable();
    },
    methods: {
      // 排序
      async sortList(updown,item){
        console.log("item",item)
        const response = await putminaRecommendList(updown,item.id)
        if (response.code === 0) {
          this.getTable()
          this.$message.success('操作成功')
        } else {
          this.$message.warning(response.message);
        }
      },
      // 分页
      pageChange(page_no, page_size) {
        this.pagination.pageNum = page_no;
        this.pagination.pageSize = page_size;
        this.getTable();
      },
      // 获取列表
      async getTable() {
        let data = {
          pageNo:this.pagination.pageNum,
          pageSize:this.pagination.pageSize
        }
        const response = await getminaRecommendList(data)
        if (response.code === 0) {
          this.HeplTable = response.data;
          this.pagination.total = response.count;
        } else {
          this.$message.warning(response.message);
        }
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .datePicker {
    background-color: #fff;
    width: 100%;
    height: 64px;
    padding: 20px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
  }
  ::v-deep .ant-table-thead > tr > th {
    font-weight: bold;
  }
  </style>
  